function sidebarAdjust () {
  if ($(window).width() <= 1024) {
    if (!$('.sidebar').hasClass('collapse')) {
      $('.sidebar').addClass('collapse')
    }

    // if ($(window).scrollTop() <= $('header').height()) {
    //   $('.sidebar').css('top', ($('.main').offset().top - $(window).scrollTop()) + 'px');    
    // }
    // else 
    // {
    //   $('.sidebar').css('top', 0);
    // }
    
    
    //$('.sidebar').css('height', ($(window).height()) + 'px');
  }
}

$(document).ready(function () {
  $(window).on('resize', sidebarAdjust);
  sidebarAdjust();

  $(document).on('click', '.btn-search-mobile', function () {
    $('.primary-header .col-search').slideToggle();
  });

  $(document).on('click', '.mobile-menu', function () {
    if ($('.sidebar').hasClass('collapse')) {
      $('.sidebar').removeClass('collapse');
      return;
    }
    $('.sidebar').addClass('collapse');
  });

  $(document).on('click', '.close-sidebar', function () {
    $('.sidebar').addClass('collapse');
  });
});